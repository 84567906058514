import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import * as d3 from 'd3';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';

import {
  getEcg,
  getYMinMaxValues,
} from 'common/modules/eventStrip/ducks/selectors';
import { abnormalityTypeEnum } from 'common/constants/ecgEnums';
import { eventTriageStripSettings } from 'common/modules/eventStrip/constants';
import { getRecordsOverlappedByRange } from 'common/utils/helpers/date';
import Button from 'common/components/buttons/ModalActionButton';
import resources from 'common/constants/resources';
import dataProvider from 'store/dataProvider';
import moment from 'moment';
import { v4 as uuidV4 } from 'uuid';

import Header from './Header';
// import EventsNav from './EventsNav';
import LoaderOverlay from './LoaderOverlay';
import EventStripGraph from './EventStripGraph';
import useRouterInfo from '../../../hooks/useRouterInfo';
import { peaksAiaColors } from '../constants/enums';

const defaultStripSettings = eventTriageStripSettings.reduce(
  (acc, val) => ({
    ...acc,
    [val.name]: val.defaultValue,
  }),
  {}
);

const AnalyzeModal = ({ resource, center, onClose }) => {
  const routerInfo = useRouterInfo();
  const procedureId = routerInfo?.params?.id;
  const graphContainer = useRef(null);
  const [aiEvents, setAiEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [maxWidth, setMaxWidth] = useState(700);
  const [visibleRange, setVisibleRange] = useState(null);
  const [analyzedBeats, setAnalyzedBeats] = useState([]);
  const [heartRates, setHeartRates] = useState([]);
  const [stripSettings, setStripSettings] = useState(defaultStripSettings);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedBeatRange, setSelectedBeatRange] = useState(null);
  // eslint-disable-next-line
  const [currentCenter, setCenter] = useState(center);
  const currentRangeRef = useRef([]);
  const [ecgData, setEcgData] = useState([]);

  const ecg = useSelector(getEcg(resource));
  const { min: yMin, max: yMax } = useSelector(getYMinMaxValues(resource));

  const xDomain = useMemo(() => d3.extent(ecg, (d) => new Date(d.time)), [ecg]);

  const yDomain = useMemo(() => [yMin - 225, yMax + 225], [yMin, yMax]);

  const focusOptions = useMemo(() => {
    return {
      center: currentCenter || center,
      domain: {
        x: xDomain,
        y: yDomain,
      },
      duration: 10,
    };
  }, [center, xDomain, yDomain, currentCenter]);

  const handleRangeChange = useCallback((range) => {
    currentRangeRef.current = range;
  }, []);

  const startAnalyze = useCallback(
    async () => {
      if (!procedureId) {
        return;
      }

      setLoading(true);
      setAiEvents([]);
      setHeartRates([]);

      const aiaPeaks = analyzedBeats;
      const aiaEvents = aiEvents;
      let heartRatesResponse = heartRates;
      const predictionCenter = center;

      try {
        const { data } = await dataProvider.createOne(resources.aia.predict, {
          query: {
            dateFrom: moment(predictionCenter)
              .subtract(1, 'minutes')
              .toISOString(),
            dateTo: moment(predictionCenter).add(1, 'minutes').toISOString(),
            procedureId,
          },
        });

        if (data.success !== true) {
          // eslint-disable-next-line
          console.log(data.message);
          return;
        }

        setEcgData(data.result.ecg);

        data.result.responseItems.map((predictResult) => {
          const predictedEventType = predictResult.strategy.metaData.eventType;

          if (predictedEventType === abnormalityTypeEnum.heartRate) {
            heartRatesResponse = predictResult.ecgEvents;
            return;
          }

          if (
            predictedEventType === abnormalityTypeEnum.pPeak ||
            predictedEventType === abnormalityTypeEnum.qPeak ||
            predictedEventType === abnormalityTypeEnum.rPeak ||
            predictedEventType === abnormalityTypeEnum.sPeak ||
            predictedEventType === abnormalityTypeEnum.tPeak
          ) {
            const currentPeaks = {
              peaks: predictResult.ecgEvents,
              color: peaksAiaColors[predictedEventType],
            };
            const existingPeakIndex = aiaPeaks.findIndex(
              (peak) => peak.predictedEventType === predictedEventType
            );

            if (existingPeakIndex !== -1) {
              aiaPeaks[existingPeakIndex].currentPeaks = currentPeaks;
            } else {
              aiaPeaks.push({ predictedEventType, currentPeaks });
            }
            return;
          }

          const updatedResult = predictResult.ecgEvents.map((obj) => ({
            ...obj,
            abnormalityType: obj.eventType,
            id: uuidV4(),
          }));

          const existingEventIndex = aiaEvents.findIndex(
            (event) => event.predictedEventType === predictedEventType
          );

          if (existingEventIndex !== -1) {
            aiaEvents[existingEventIndex].events = updatedResult;
          } else {
            aiaEvents.push({ predictedEventType, events: updatedResult });
          }

          // eslint-disable-next-line
          return {};
        });
      } finally {
        setLoading(false);
        setAnalyzedBeats(aiaPeaks.flatMap((peaks) => peaks.currentPeaks));
        setAiEvents(aiaEvents.flatMap((events) => events.events));
        setHeartRates(heartRatesResponse);
      }
    },
    // eslint-disable-next-line
    [center, procedureId]
  );

  // eslint-disable-next-line
  const visibleEvents = useMemo(() => {
    return (
      getRecordsOverlappedByRange(
        aiEvents,
        [visibleRange].flat().filter(Boolean)
      ),
      [aiEvents, visibleRange]
    );
  });

  const graphClickHandler = useCallback((bRange) => {
    setSelectedBeatRange(bRange);
    setSelectedEvent(null);
  }, []);

  const groupedEvents = useMemo(
    () => groupBy(aiEvents, 'abnormalityType'),
    [aiEvents]
  );

  const countLabel = useMemo(() => {
    const eventKeys = Object.keys(groupedEvents);

    if (!eventKeys.length) {
      return '0';
    }

    return eventKeys
      .map((abT) => `${abT} - ${groupedEvents[abT].length}`)
      .join(', ');
  }, [groupedEvents]);

  const handleManualAnalyze = () => {
    return startAnalyze();
  };

  useEffect(() => {
    if (!graphContainer.current) {
      return;
    }

    setMaxWidth(graphContainer.current.clientWidth - 32);
  }, [graphContainer]);

  useEffect(() => {
    startAnalyze();
  }, [startAnalyze]);

  return (
    <Card
      style={{
        flex: 1,
        position: 'relative',
        maxHeight: '80vh',
        overflowY: 'auto',
      }}
    >
      {loading && <LoaderOverlay />}

      <Header
        isModalView
        resource={resource}
        selectedEvent={selectedEvent}
        stripSettings={stripSettings}
        setStripSettings={setStripSettings}
        selectedBeatRange={selectedBeatRange}
      />

      <Grid
        item
        container
        direction="column"
        ref={graphContainer}
        style={{ position: 'relative', padding: '8px 16px' }}
      >
        <EventStripGraph
          height={350}
          width={maxWidth}
          onClick={graphClickHandler}
          settings={stripSettings}
          resource={resource}
          ecgData={ecgData}
          aiEvents={visibleEvents}
          selectedEvent={selectedEvent}
          position={selectedBeatRange}
          visibleRange={visibleRange}
          focusOptions={focusOptions}
          analyzedBeats={analyzedBeats}
          heartRates={heartRates}
          setVisibleRange={setVisibleRange}
          handleEventSelect={setSelectedEvent}
          onRangeChange={handleRangeChange}
        />
      </Grid>

      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        style={{ padding: '8px 16px' }}
      >
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          style={{ paddingBottom: '8px', gap: 24 }}
        >
          <Grid item container style={{ gap: 10 }}>
            <Grid item style={{ marginTop: '12px' }}>
              <Typography>
                <span style={{ backgroundColor: 'blue', color: 'white' }}>
                  P peak
                </span>{' '}
                <span style={{ backgroundColor: 'green', color: 'white' }}>
                  Q peak
                </span>{' '}
                <span style={{ backgroundColor: 'red', color: 'white' }}>
                  R peak
                </span>{' '}
                <span style={{ backgroundColor: 'purple', color: 'white' }}>
                  S peak
                </span>{' '}
                <span style={{ backgroundColor: 'orange', color: 'white' }}>
                  T peak
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          alignItems="center"
          xs={6}
          style={{ marginTop: 10 }}
        >
          <Button onClick={handleManualAnalyze} disabled={loading}>
            Analyze
          </Button>

          <Typography style={{ paddingLeft: 16 }}>
            AIA events: {loading ? 'Analysis...' : countLabel}
          </Typography>
        </Grid>

        <Grid item style={{ display: 'flex', gap: 16 }}>
          {/* <EventsNav
            setCenter={setCenter}
            allEvents={aiEvents}
            visibleRange={visibleRange}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            selectedBeatRange={selectedBeatRange}
            setSelectedBeatRange={setSelectedBeatRange}
          /> */}

          <Button onClick={onClose}>Close</Button>
        </Grid>
      </Grid>
    </Card>
  );
};

AnalyzeModal.propTypes = {
  center: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
};

export default AnalyzeModal;
