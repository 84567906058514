import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'common/components/buttons/ModalActionButton';
import { openAiAnalyze } from 'common/ducks/general/actions/modalActions';

import { getLoading } from '../eventStrip/ducks/selectors';

const AiAnalyzeButton = ({ resource, center }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoading(resource));

  return (
    <Grid item>
      <Button
        disabled={loading}
        onClick={() => dispatch(openAiAnalyze({ resource, center }))}
      >
        AI Analyze
      </Button>
    </Grid>
  );
};

AiAnalyzeButton.propTypes = {
  resource: PropTypes.string.isRequired,
  center: PropTypes.any.isRequired,
  event: PropTypes.shape({
    dateFrom: PropTypes.string.isRequired,
    procedureId: PropTypes.string.isRequired,
    abnormalityType: PropTypes.string.isRequired,
  }),
};

export default AiAnalyzeButton;
