import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  eventType,
  containerChildProps,
} from 'common/constants/sharedPropTypes';

const HeartRate = ({ parent, event, bottom = 10 }) => {
  const { height, scale } = parent;
  const textRef = useRef(null);
  const [showHint, setShowHint] = useState(false);
  const yPosition = useMemo(() => height - bottom, [height, bottom]);

  const textX = useMemo(
    () => (scale ? scale(new Date(event.dateFrom)) : 0),
    [scale, event.dateFrom]
  );

  return (
    <g
      onMouseEnter={() => setShowHint(true)}
      onMouseLeave={() => setShowHint(false)}
    >
      {/* Render main text with event value */}
      <text
        ref={textRef}
        fontSize="15px"
        fontWeight="500"
        x={textX - 10}
        y={yPosition}
        fill="black"
      >
        {`${Number(event.value).toFixed(0)}`}
      </text>

      {showHint && (
        <text
          fontSize="12px"
          x={textX}
          y={yPosition - 30}
          fill="black"
          style={{
            backgroundColor: 'white',
            padding: '2px',
            borderRadius: '3px',
          }}
        >
          Heart Rate
        </text>
      )}
    </g>
  );
};

HeartRate.propTypes = {
  event: eventType,
  bottom: PropTypes.number,
  parent: containerChildProps,
};

export default HeartRate;
